<div class="content-redes-sociais">
  <div class="container">
      <div class="title">
          <div class="box">
              <span class="linha"></span>
          </div>
          <div class="box">
              <strong>SIGA A GRAMADO RECEPTIVO NAS REDES SOCIAIS</strong>
          </div>
          <div class="box">
              <span class="linha"></span>
          </div>
      </div> 
      <div class="list">
          <ul>
              <li>
                  <a [href]="data.params.facebook" title="Facebook" target="_blank">
                      <div class="icon">
                          <i class="fa-brands fa-facebook"></i>
                      </div>
                  </a>    
              </li>
              <li>
                  <a [href]="data.params.facebook" title="Instagram" target="_blank">
                      <div class="icon">
                          <i class="fa-brands fa-instagram"></i>
                      </div>
                  </a>    
              </li>
          </ul>    
      </div>    
  </div>
</div>